import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { ToastrModule } from "ngx-toastr";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { MaterialModule } from "./material";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { UserGuard } from "./Auth/user.guard";
import { AuthService } from "./Auth/auth.service";
import { NotFoundComponent } from "./not-found/not-found.component";
import { DatePipe } from "@angular/common";
import { FilterPipeModule } from "ngx-filter-pipe";
import { NgxPaginationModule } from "ngx-pagination";
import { BreadcrumbModule } from "angular-crumbs";
import { CommingSoonComponent } from "./comming-soon/comming-soon.component";
import { ChartsModule } from "ng2-charts";
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotFoundComponent,
    CommingSoonComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FilterPipeModule,
    NgxPaginationModule,
    BreadcrumbModule,
    ChartsModule,
    // AutocompleteModule.forRoot(),
    MaterialModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
    }),
  ],
  providers: [UserGuard, AuthService, DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
