import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class ApiService {
  // private _BaseUrl = "http://13.234.204.118:5000/AdminApp"
  private _BaseUrl = "https://canadalogistic.metalsart.in/AdminApp";
  token: any;

  constructor(private _http: HttpClient) {
    this.token = localStorage.getItem("isLoogedIn");
    // console.log(this.token)
  }

  // '1' *******Login /

  isLogin(data: any) {
    return this._http
      .post(`${this._BaseUrl}/LoginAPI/`, data)
      .pipe(catchError(this.errorHandler));
  }

  //2  SuperAdminAPI Add  API
  superAdminAdd(data: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/SuperAdminAPI/`, data, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //3 SuperAdminAPI Active List Api
  getInactiveSuperAdminList() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/SuperAdminAPI/?status=0`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //4 Get Inactive List SuperAdminAPI
  getActiveSuperAdminList() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/SuperAdminAPI/?status=1`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //5 **** SuperAdminAPI Delete APi

  deleteSuperAdmin(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/SuperAdminDeleteAPI/`, id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //6 **** SuperAdminAPI Data Api
  superAdminData(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/SuperAdminAPI/?id=` + id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //7 ****SuperAdminAPI Update Api
  updateSuperAdmin(data: any) {
    const optHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/SuperAdminAPI/`, data, {
        headers: optHeader,
      })
      .pipe(catchError(this.errorHandler));
  }

  // ------------------------------------------------------------------------

  //8  Trip Status Add  API
  tripStatusAdd(data: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/TripStatusAPI/`, data, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //9 trip status Active List Api
  getInactiveTripStatusList() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/TripStatusAPI/?status=0`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //10 Get Inactive List Trip Status
  getActiveTripStatusList() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/TripStatusAPI/?status=1`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //11 **** Trip Statsus Delete APi

  deleteTripStatus(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/TripStatusDeleteAPI/`, id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //12 **** Trip Status Data Api
  TripStatusData(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/TripStatusAPI/?id=` + id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //13 ****TripStatusAPI Update Api
  updateTripStatus(data: any) {
    const optHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/TripStatusAPI/`, data, {
        headers: optHeader,
      })
      .pipe(catchError(this.errorHandler));
  }

  //14 vendor Add  API
  vendorAdd(data: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/VendorAPI/`, data, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //15 vendor Active List Api
  getInactiveVendorList() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/VendorAPI/?status=0`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //16 Get Inactive List vendor
  getActiveVendorList() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/VendorAPI/?status=1`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //17 **** Vendor Delete APi

  deleteVendor(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/VendorDeleteAPI/`, id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //18 **** Vendor Data Api
  vendorData(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/VendorAPI/?id=` + id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //19 ****Vendor Update Api
  updateVendor(data: any) {
    const optHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/VendorAPI/`, data, {
        headers: optHeader,
      })
      .pipe(catchError(this.errorHandler));
  }

  //20 trailer Add  API
  trailerAdd(data: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/TrailerAPI/`, data, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //21 Traliler Active List Api
  getInactiveTrailerList() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/TrailerAPI/?status=0`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //22 Get Inactive List Trailer
  getActiveTrailerList() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/TrailerAPI/?status=1`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //23 **** Trailer Delete APi

  deleteTrailer(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/TrailerDeleteAPI/`, id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //24 **** Trailer Data Api
  trailerData(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/TrailerAPI/?id=` + id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //25 ****Trailer Update Api
  updateTrailer(data: any) {
    const optHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/TrailerAPI/`, data, {
        headers: optHeader,
      })
      .pipe(catchError(this.errorHandler));
  }

  //26 licence Add  API
  licenceAdd(data: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/LicenseClassAPI/`, data, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //27 Licence Active List Api
  getInactiveLicenceList() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/LicenseClassAPI/?status=0`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //28 Get Inactive List Licence
  getActiveLicenceList() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/LicenseClassAPI/?status=1`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //28 **** Licence Delete APi

  deleteLicence(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/LicenseClassDeleteAPI/`, id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //29 **** Licence Data Api
  licenceData(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/LicenseClassAPI/?id=` + id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //30 ****Licence Update Api
  updateLicence(data: any) {
    const optHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/LicenseClassAPI/`, data, {
        headers: optHeader,
      })
      .pipe(catchError(this.errorHandler));
  }

  //31 EndorsementAPI Add  API
  endorsementAdd(data: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/EndorsementAPI/`, data, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //32 Endorsement Active List Api
  getInactiveEndorsementList() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/EndorsementAPI/?status=0`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //33 Get Inactive List Endorsement
  getActiveEndorsementList() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/EndorsementAPI/?status=1`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //34 **** Endorsement Delete APi

  deleteEndorsement(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/EndorsementDeleteAPI/`, id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //35 **** Endorsement Data Api
  endorsementData(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/EndorsementAPI/?id=` + id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //36 ****Endorsement Update Api
  updateEndorsement(data: any) {
    const optHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/EndorsementAPI/`, data, {
        headers: optHeader,
      })
      .pipe(catchError(this.errorHandler));
  }
  //37 trailerTarpAdd Add  API
  trailerTarpAdd(data: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/TrailerTarpAPI/`, data, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //38 trailer tarp Active List Api
  getInactiveTrailerTarpList() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/TrailerTarpAPI/?status=0`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //39 Get Inactive List TrailerTarp
  getActiveTrailerTarpList() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/TrailerTarpAPI/?status=1`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //40 **** TrailerTarp Delete APi

  deleteTrailerTarp(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/TrailerTarpDeleteAPI/`, id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //41 **** TrailerTarp Data Api
  trailerTarpData(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/TrailerTarpAPI/?id=` + id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //42 ****TrailerTarp Update Api
  updateTrailerTarp(data: any) {
    const optHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/TrailerTarpAPI/`, data, {
        headers: optHeader,
      })
      .pipe(catchError(this.errorHandler));
  }

  //43  trip Add  API
  tripAdd(data: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/TripAPI/`, data, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //44 trip Active List Api
  getInactiveTripList() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/TripAPI/?status=0`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //45 Get Inactive List Trip
  getActiveTripList() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/TripAPI/?status=1`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //46 **** Trip Delete APi

  deleteTrip(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/TripDeleteAPI/`, id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //47 **** Trip Data Api
  tripData(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/TripAPI/?id=` + id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //48 ****Trip Update Api
  updateTrip(data: any) {
    const optHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/TripAPI/`, data, {
        headers: optHeader,
      })
      .pipe(catchError(this.errorHandler));
  }

  //49  Notification Add  API
  notificationAdd(data: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/NotificationAPI/`, data, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //50 NotificationAPI Active List Api
  getInactiveNotificationAPIList() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/NotificationAPI/?status=0`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //51 Get Inactive NotificationAPI Trip
  getActiveNotificationAPI() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/NotificationAPI/?status=1`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //52 **** NotificationAPI Delete APi

  deleteNotificationAPI(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/NotificationDeleteAPI/`, id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //53 **** NotificationAPI Data Api
  NotificationAPIData(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/NotificationAPI/?id=` + id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //54 ****NotificationAPI Update Api
  updateNotificationAPI(data: any) {
    const optHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/NotificationAPI/`, data, {
        headers: optHeader,
      })
      .pipe(catchError(this.errorHandler));
  }

  //55  ClientAPI Add  API
  clientAdd(data: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/ClientAPI/`, data, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //56 ClientAPI Active List Api
  getInactiveClientAPI() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/ClientAPI/?status=0`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //57 Get Inactive ClientAPI Trip
  getActiveClientAPI() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/ClientAPI/?status=1`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //58 **** ClientAPI Delete APi

  deleteClientAPI(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/ClientDeleteAPI/`, id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //59 **** ClientAPI Data Api
  ClientAPIData(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/ClientAPI/?id=` + id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //60 **** ClientAPI Update Api
  updateClientAPI(data: any) {
    const optHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/ClientAPI/`, data, {
        headers: optHeader,
      })
      .pipe(catchError(this.errorHandler));
  }

  //61  VehicleDetailAPI Add  API
  vehicleAdd(data: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/VehicleDetailAPI/`, data, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //62 VehicleDetail Active List Api
  getInactiveVehicleDetail() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/VehicleDetailAPI/?status=0`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //63 Get Inactive VehicleDetail Trip
  getActiveVehicleDetail() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/VehicleDetailAPI/?status=1`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //64 **** VehicleDetail Delete APi

  deleteVehicleDetail(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/VehicleDetailDeleteAPI/`, id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //65 **** VehicleDetail Data Api
  VehicleDetailData(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/VehicleDetailAPI/?id=` + id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //66 **** VehicleDetail Update Api
  updateVehicleDetail(data: any) {
    const optHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/VehicleDetailAPI/`, data, {
        headers: optHeader,
      })
      .pipe(catchError(this.errorHandler));
  }

  //67  DriverAPI Add  API
  driverAdd(data: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/DriverAPI/`, data, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //68  Driver Active List Api
  getInactiveDriver() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/DriverAPI/?status=0`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //69 Get Inactive Driver Trip
  getActiveDriver() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/DriverAPI/?status=1`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //70 **** Driver Delete APi

  deleteDriver(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/DriverDeleteAPI/`, id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //71 **** Driver Data Api
  DriverData(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/DriverAPI/?id=` + id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //72 **** Driver Update Api
  updateDriver(data: any) {
    const optHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/DriverAPI/`, data, {
        headers: optHeader,
      })
      .pipe(catchError(this.errorHandler));
  }

  //73  AssignTripAPI Add  API
  assignTripAdd(data: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/AssignTripAPI/`, data, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //74  AssignTrip Active List Api
  getInactiveAssignTrip() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/AssignTripAPI/?status=0`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //75 Get Inactive AssignTrip Trip
  getActiveAssignTrip() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/AssignTripAPI/?status=1`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //76 **** AssignTrip Delete APi

  deleteAssignTrip(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/AssignTripDeleteAPI/`, id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //77 **** AssignTrip Data Api
  AssignTripData(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/AssignTripAPI/?id=` + id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //78 **** AssignTrip Update Api
  updateAssignTrip(data: any) {
    const optHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/AssignTripAPI/`, data, {
        headers: optHeader,
      })
      .pipe(catchError(this.errorHandler));
  }

  //79  TripCurrentLocationAPI Add  API
  tripCurrentLocationAdd(data: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/TripCurrentLocationAPI/`, data, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //80  TripCurrentLocation Active List Api
  getInactiveTripCurrentLocation() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/TripCurrentLocationAPI/?status=0`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //81 Get Inactive TripCurrentLocation Trip
  getActiveTripCurrentLocation() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/TripCurrentLocationAPI/?status=1`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //82 **** TripCurrentLocation Delete APi

  deleteTripCurrentLocation(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/TripCurrentLocationDeleteAPI/`, id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //83 **** TripCurrentLocation Data Api
  TripCurrentLocationData(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/TripCurrentLocationAPI/?id=` + id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //84 **** TripCurrentLocation Update Api
  updateTripCurrentLocation(data: any) {
    const optHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/TripCurrentLocationAPI/`, data, {
        headers: optHeader,
      })
      .pipe(catchError(this.errorHandler));
  }

  //2  AddTripAPI Add  API
  AddTripAPIAdd(data: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/AddTripAPI/`, data, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //3 AddTripAPI Active List Api

  //4 Get Inactive List AddTripAPI
  getActiveAddTripAPIList() {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .get(`${this._BaseUrl}/AddTripAPI/?status=1`, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //5 **** AddTripAPI Delete APi

  deleteAddTripAPI(id: any) {
    const opt = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/AddTripAPIDeleteAPI/`, id, {
        headers: opt,
      })
      .pipe(catchError(this.errorHandler));
  }

  //6 **** AddTripAPI Data Api

  //7 ****AddTripAPI Update Api
  updateAddTripAPI(data: any) {
    const optHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.token,
    });
    return this._http
      .post(`${this._BaseUrl}/AddTripAPI/`, data, {
        headers: optHeader,
      })
      .pipe(catchError(this.errorHandler));
  }

  /** Error Handling method */

  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
